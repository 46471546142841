#College {
  border-bottom: 1px solid gray;
}

#CertIcons{

  img{
    width: 180px;
    height:180px;
  }

  @media screen and (max-width: 440px){
    img{
      width: 35vw;
      height: 35vw;
    }
  }
}